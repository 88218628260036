import { MessageCampaignMessageList } from '../components/MessageCampaign/Message';

function MessageCampaignMessagesRoute() {
  return <MessageCampaignMessageList />;
}

export const Component = MessageCampaignMessagesRoute;

export function clientLoader() {
  return null;
}
